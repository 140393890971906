<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="6">
        <app-text-field
          class="mr-4"
          label="Data"
          solo
          flat
          background-color="grey lighten-4"
          v-model="params.date_start"
          type="date"
        />
        <app-text-field
          class="mr-4"
          label="Data"
          solo
          flat
          background-color="grey lighten-4"
          v-model="params.date_end"
          type="date"
        />
        <v-btn class="mr-4" color="primary" @click="select()">Pesquisar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>{{ saleByCompany.title }}</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <template
                  v-for="(metric, index) in saleByCompany.metricsLabels"
                >
                  <th class="text-right" :key="index">
                    {{ metric.label }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in saleByCompany.data">
                <tr :key="index">
                  <td>{{ saleByCompany.dimension1Label(row) }}</td>
                  <!-- <td>{{ $format.decimal(row[saleBySalesman.metrics[0]])}}</td>   -->
                  <template
                    v-for="(metric, index) in saleByCompany.metricsLabels"
                  >
                    <td class="text-right" :key="index">
                      {{ metric.value(row) }}
                    </td>
                  </template>
                </tr>
              </template>
              <tr>
                <td></td>
                <td class="text-right">
                  {{
                    $format.decimal(
                      saleByCompany.data.reduce(
                        (a, b) => a + Number(b.net_price_cash_total_sum),
                        0
                      )
                    )
                  }}
                </td>
                <td class="text-right">
                  {{
                    $format.decimal(
                      saleByCompany.data.reduce(
                        (a, b) => a + Number(b.profit_sum),
                        0
                      )
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col>
        <h3>{{ saleBySalesman.title }}</h3>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <template
                  v-for="(metric, index) in saleByCompany.metricsLabels"
                >
                  <th class="text-right" :key="index">
                    {{ metric.label }}
                  </th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in saleBySalesman.data">
                <tr :key="index">
                  <td>{{ saleBySalesman.dimension1Label(row) }}</td>
                  <!-- <td>{{ $format.decimal(row[saleBySalesman.metrics[0]])}}</td>   -->
                  <template
                    v-for="(metric, index) in saleByCompany.metricsLabels"
                  >
                    <td class="text-right" :key="index">
                      {{ metric.value(row) }}
                    </td>
                  </template>
                </tr>
              </template>
              <tr>
                <td></td>
                <td class="text-right">
                  {{
                    $format.decimal(
                      saleByCompany.data.reduce(
                        (a, b) => a + Number(b.net_price_cash_total_sum),
                        0
                      )
                    )
                  }}
                </td>
                <td class="text-right">
                  {{
                    $format.decimal(
                      saleByCompany.data.reduce(
                        (a, b) => a + Number(b.profit_sum),
                        0
                      )
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import Vue from "vue";

export default {
  data: () => ({
    params: {
      date_start: format(new Date(), "yyyy-MM-01"),
      date_end: format(new Date(), "yyyy-MM-dd"),
    },

    saleByCompany: {
      type: "table",
      title: "Vendas por loja",
      metrics: ["net_price_cash_total_sum", "profit_sum"],
      dimensions: ["company_id"],
      dimension1Label: (row) => row.company.name,
      metricsLabels: [
        {
          label: "Total",
          value: (row) => Vue.$format.decimal(row.net_price_cash_total_sum),
        },
        {
          label: "Lucro",
          value: (row) =>
            Vue.$format.decimal(row.profit_sum) +
            " (" +
            Vue.$format.decimal(
              (row.profit_sum / row.net_price_cash_total_sum) * 100
            ) +
            "%)",
        },
      ],
      filters: {
        status: "sale",
        saved_at_start: {
          type: "date",
          column: "saved_at",
          operator: ">=",
          value: format(new Date(), "yyyy-MM-01"),
        },
        saved_at_end: {
          type: "date",
          column: "saved_at",
          operator: "<=",
          value: format(new Date(), "yyyy-MM-26"),
        },
      },
      data: [],
    },

    saleBySalesman: {
      type: "table",
      title: "Vendas por vendedor",
      metrics: ["net_price_cash_total_sum", "profit_sum"],
      dimensions: ["salesman_id"],
      dimension1Label: (row) => row.salesman.short_name,
      metricsLabels: [
        {
          label: "Total",
          value: (row) => Vue.$format.decimal(row.net_price_cash_total_sum),
        },
        {
          label: "Lucro",
          value: (row) =>
            Vue.$format.decimal(row.profit_sum) +
            " (" +
            Vue.$format.decimal(
              (row.profit_sum / row.net_price_cash_total_sum) * 100
            ) +
            "%)",
        },
      ],
      filters: {
        status: "sale",
        saved_at_start: {
          label: "Data inicial",
          type: "date",
          column: "saved_at",
          operator: ">=",
          value: format(new Date(), "yyyy-MM-01"),
        },
        saved_at_end: {
          type: "date",
          column: "saved_at",
          operator: "<=",
          value: format(new Date(), "yyyy-MM-dd"),
        },
      },
      data: [],
    },

    // saleByStore: {
    //   resource: "sales",
    //   metrics: ["net_price_cash_total_sum"],
    //   dimensions: ["company_id"],
    //   filters: {
    //     status: "sale",
    //     saved_at_start: {
    //       column: "saved_at",
    //       operator: ">=",
    //       value: format(new Date(), "yyyy-MM-01"),
    //     },
    //     saved_at_end: {
    //       column: "saved_at",
    //       operator: "<=",
    //       value: format(new Date(), "yyyy-MM-dd"),
    //     },
    //   },
    // },
  }),

  mounted() {
    this.select();
  },

  methods: {
    select() {
      this.setDateParams();

      this.selectSalesByCompany();
      this.selectSalesBySalesman();
    },

    setDateParams() {
      this.saleByCompany.filters.saved_at_start.value = this.params.date_start;
      this.saleByCompany.filters.saved_at_end.value = this.params.date_end;

      this.saleBySalesman.filters.saved_at_start.value = this.params.date_start;
      this.saleBySalesman.filters.saved_at_end.value = this.params.date_end;
    },

    selectSalesByCompany() {
      let params = {
        metrics: this.saleByCompany.metrics,
        dimensions: this.saleByCompany.dimensions,
        filters: this.saleByCompany.filters,
      };

      this.$http.index("bi/sale", params).then((response) => {
        this.saleByCompany.data = response.sale;
      });
    },

    selectSalesBySalesman() {
      let params = {
        metrics: this.saleBySalesman.metrics,
        dimensions: this.saleBySalesman.dimensions,
        filters: this.saleBySalesman.filters,
      };

      this.$http.index("bi/sale", params).then((response) => {
        this.saleBySalesman.data = response.sale;
      });
    },

    selectAccountTransactionByPaymentMethod() {
      let params = {
        metrics: this.saleBySalesman.metrics,
        dimensions: this.saleBySalesman.dimensions,
        filters: this.saleBySalesman.filters,
      };

      this.$http.index("bi/sale", params).then((response) => {
        this.saleBySalesman.data = response.sale;
      });
    },
  },
};
</script>

<style></style>
